import { Injectable } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CookieService } from './cookie.service';
import { UserType, UserTypeToRedirectionPortal, CookieKeys } from '../enums/enums';
import { Router } from '@angular/router';
import { DocumentVersion } from '../models/Document';
import { RaboWrapperService } from './rabo-wrapper.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UiHelperService {
    openedModal: ModalDirective;
    cookies: Array<string> = [CookieKeys.ConsentLevel, CookieKeys.WelcomeBoxState, CookieKeys.ClientBannerState];
    isDropDown$ = new Subject<boolean>();
    toggleUnauthorized$ = new Subject<boolean>();
    hide = true;
    hideConfirmPassword = true;

    constructor(
        private readonly _cookie: CookieService,
        private readonly _router: Router,
        private readonly _raboWrapper: RaboWrapperService
    ) { }

    navigateUserToPortal(userType: UserType) {
        switch (userType) {
            case UserType.SuperUser:
                this._router.navigate([`/${UserTypeToRedirectionPortal[UserType.SuperUser]}`]);
                break;
            case UserType.SuperAgent:
            case UserType.Agent:
                this._router.navigate([`/${UserTypeToRedirectionPortal[UserType.Agent]}`]);
                break;
        }
    }

    clearSession(autoRedirect: boolean = true) {
        sessionStorage.clear();
        const cookies = this._cookie.getAll();
        for (const cookieName in cookies) {
            if (cookies.hasOwnProperty(cookieName) && !this.cookies.includes(cookieName)) {
                this._cookie.delete(cookieName, '/');
            }
        }

        if (autoRedirect) {
            location.href = './login';
        }
    }

    downloadDocument(doc: DocumentVersion, documentBase64String: string, isOpenInNewTab = false) {
        if (documentBase64String) {
            const byteCharacters = atob(documentBase64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: doc.MimeType });
            const URL = window.URL || window.webkitURL;
            const downloadUrl = URL.createObjectURL(blob);

            if (!isOpenInNewTab) {
                const link = document.createElement('a');
                link.download = doc.DocumentName;
                link.href = downloadUrl;
                link.style.display = 'block';
                link.target = "_blank";
                link.style.height = '0';
                link.style.width = '0';
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
            else {
                window.open(downloadUrl);
            }
        }
    }

    onClickPasswordToggle() {
        this.hide = !this.hide;
    }

    onClickConfirmPasswordToggle() {
        this.hideConfirmPassword = !this.hideConfirmPassword;
    }

    calculateFileMaxSize(maxSize: number, fileSize: number): boolean {
        const maxFileSize = maxSize * 1024 * 1024;
        return fileSize >= maxFileSize;
    }

    calculateProgressBar(currentStep: number, totalStep: number): number {
        return currentStep >= totalStep ? 100 : ((currentStep * 100) / totalStep);
    }
}
