import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginRedirectComponent } from './modules/login-redirect/login-redirect.component';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./modules/maintenance/maintenance.module').then(m => m.MaintenanceModule)
    },
    {
        path: 'intermediary',
        loadChildren: () => import('./modules/intermediary/intermediary.module').then(m => m.IntermediaryModule),
        data: {
            breadcrumb: 'Overzicht'
        }
    },
    {
        path: 'intermediary-admin',
        loadChildren: () => import('./modules/intermediary-admin/intermediary-admin.module').then(m => m.IntermediaryAdminModule),
        data: {
            breadcrumb: 'Overzicht'
        }
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'loginredirect',
        component: LoginRedirectComponent
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
