import { Injectable } from '@angular/core';
import { FetchClient } from '@yellowtail-frontend/cord-connector';
import { environment } from 'src/environments/environment';
import { CordEmailRequest } from '../models/cord-email.model';
import { DeleteSuperAgentRequest, SyncFailedTask } from '../models/super-agent-model';
import { MaintenanceBannerDetail } from '../models/generic.model';
import { PeriodicValidationData, PeriodicValidationRequest } from '../models/periodic-validation.model';
import { UpdateSuperAgentProfileRequest, UpdateAgentProfileRequest } from '../models/profile-update-request.model';
import { SignalRequest } from '../models/rabo-signal.model';
import { ResetPasswordRequest } from '../models/reset-password-request';
import { ResetSmsPasswordRequest } from '../models/reset-sms-password-request';
import { SmsPasswordEmailRequest } from '../models/sms-password-email-request';
import { User, TourDetails } from '../models/user.model';
import { NewsArticleDetail, NewsArticleList, NewsArticleTagResponse, UmbracoStatisticRequest } from '../models/news-article.model';
import { PasswordResetActivityRequest, PasswordResetActivityResponse } from '../models/password-reset';
import { LeadTimeResponse } from '../models/lead-time';

@Injectable({
    providedIn: 'root'
})
export class RaboWrapperService extends FetchClient {

    constructor() {
        super();
        this.baseUrl = environment.raboWrapperBaseUrl;
    }

    createAgent(data: any): Promise<any> {
        return this.post('user/createAccount/agent', data);
    }

    createSuperAgent(data: any): Promise<any> {
        return this.post('user/createAccount/superAgent', data);
    }

    getInterestRates(): Promise<any> {
        return this.get('configuration/InterestRatesTable');
    }

    getEligibleSuperAgents(organizationId: string): Promise<any> {
        return this.get('user/retrieveEligibleSuperAgents/' + organizationId);
    }

    getMaintenanceConfig(): Promise<MaintenanceBannerDetail> {
        return this.get('configuration/MaintenanceConfiguration', 'true');
    }

    sendRioPortalEmail(data: CordEmailRequest): Promise<any> {
        return this.post(`account/sendRioPortalEmail`, data);
    }

    validateReCaptcha(key): Promise<any> {
        return this.post(`validation/reCaptcha`, key);
    }

    downloadPDF(applicationId: string): Promise<any> {
        return this.get('report/' + applicationId);
    }

    deleteSuperAgent(data: DeleteSuperAgentRequest): Promise<any> {
        return this.post('user/DeleteAccount/SuperAgent', data);
    }

    updateNotificationStatus(applicationId: string, signalId: string, statusId: string, data: SignalRequest): Promise<any> {
        return this.put(`client/notificationStatus/${applicationId}/${signalId}/${statusId}`, data);
    }

    requestSMSPassword(smsPasswordEmailRequest: SmsPasswordEmailRequest): Promise<any> {
        return this.post('account/requestSMSPassword', smsPasswordEmailRequest);
    }

    sendSMSPassword(resetSmsPasswordRequest: ResetSmsPasswordRequest): Promise<any> {
        return this.post('account/sendSMSPassword', resetSmsPasswordRequest);
    }

    getApplicationId(raboClientId: string): Promise<string> {
        return this.get(`client/application/${raboClientId}`);
    }

    getProfile(): Promise<User> {
        return this.get('user/getUserProfile');
    }

    requestResetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<any> {
        return this.post('account/requestResetPassword', resetPasswordRequest);
    }

    getPeriodicValidationData(organizationId: string): Promise<PeriodicValidationData> {
        return this.get(`PeriodicValidation/getstatus/${organizationId}`);
    }

    updateOrganizationPeriodicData(periodicValidationRequest: PeriodicValidationRequest): Promise<void> {
        return this.patch(`PeriodicValidation/updatestatus`, periodicValidationRequest);
    }

    sendSuperAgentVerficationOtp(updateSuperAgentProfileRequest: UpdateSuperAgentProfileRequest): Promise<string> {
        return this.post('user/sendUserVerficationOtp', updateSuperAgentProfileRequest);
    }

    verifySuperAgentOtp(verifyUserOtpRequest: UpdateSuperAgentProfileRequest): Promise<string> {
        return this.post('user/verifyUserOtp', verifyUserOtpRequest);
    }

    updateAgentProfile(updateAgentProfileRequest: UpdateAgentProfileRequest): Promise<void> {
        return this.post('user/updateUserProfile/agent', updateAgentProfileRequest);
    }

    sendAgentVerficationOtp(updateAgentProfileRequest: UpdateAgentProfileRequest): Promise<void> {
        return this.post('user/sendVerificationOtp/agent', updateAgentProfileRequest);
    }

    getTourStatus(): Promise<TourDetails> {
        return this.get('user/tourStatus');
    }

    saveTourStatus(status: boolean): Promise<void> {
        return this.post(`user/tourStatus/${status}`, '');
    }

    getNewsTags(): Promise<NewsArticleTagResponse> {
        return this.get('umbraco/articletags');
    }

    getNewsArticles(): Promise<NewsArticleList> {
        return this.get('umbraco/newsarticles');
    }

    getNewsArticlesByTags(tags: Array<string>): Promise<NewsArticleTagResponse> {
        return this.post('umbraco/filterarticlesbytags', tags);
    }

    getNewsArticleDetails(id: number): Promise<NewsArticleDetail> {
        return this.get(`umbraco/newsarticles/${id}`);
    }

    syncSuperAgentFailed(taskName: string): Promise<SyncFailedTask[]> {
        return this.get(`account/retrieveSyncFailedTask/${taskName}`);
    }

    logResetPasswordActivity(passwordResetActivityRequest: PasswordResetActivityRequest): Promise<void> {
        return this.post('account/passwordResetLogActivity', passwordResetActivityRequest);
    }

    getResetPasswordActivity(email: string): Promise<PasswordResetActivityResponse[]> {
        return this.get(`account/passwordResetLogActivity/${email}`);
    }

    saveUmbracoStatistic(umbracoStatisticRequest: UmbracoStatisticRequest): Promise<void> {
        return this.post(`umbracostatistic/statistic`, umbracoStatisticRequest);
    }

    updateUmbracoRating(umbracoRatingRequest: UmbracoStatisticRequest): Promise<void> {
        return this.put(`umbracostatistic/rating`, umbracoRatingRequest);
    }

    getDocumentVersionBase64(docType: string): Promise<string> {
        return this.get(`document/getDocumentVersion/${docType}`);
    }

    getLeadTime(): Promise<LeadTimeResponse> {
        return this.get('umbraco/leadtime');
    }
}
