import { Component, ViewChild, OnInit } from '@angular/core';
import { ModalHelperService } from './modules/shared/components/modal/modal-helper.service';
import { RaboWrapperService } from './modules/shared/services/rabo-wrapper.service';
import { Router } from '@angular/router';
import { ModalComponent } from 'src/app/modules/shared/components/modal/modal.component';
import { OnboardingTourService } from './modules/shared/services/onboarding-tour.service';
import { TealiumAnalyticsService } from './modules/shared/services/tealium-analytics.service';
import { AccessManagerService } from './modules/shared/services/access-manager.service';
import { UiHelperService } from './modules/shared/services/ui-helper.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    title = 'rabobank-intermediair';

    @ViewChild('modalTerms', { static: true }) private modalTerms: ModalComponent;
    @ViewChild('modalSessionExpired', { static: true }) private modalSessionExpired: ModalComponent;

    constructor(
        private readonly _modalHelper: ModalHelperService,
        private readonly _raboWrapper: RaboWrapperService,
        private readonly _router: Router,
        public _onboardingTour: OnboardingTourService,
        public _tealiumAnalytics: TealiumAnalyticsService,
        private readonly _accessManager: AccessManagerService,
        private readonly _uiHelper: UiHelperService,
    ) {
        this._raboWrapper.getMaintenanceConfig().then(resp => {
            if (resp?.MaintenancePageUpMajor) {
                this._router.navigate(['/maintenance'], { queryParams: { view: 'majorDisruption' } });
            }
            else if (resp?.MaintenancePageUpMinor) {
                this._router.navigate(['/maintenance']);
            }
            this._accessManager.maintenanceBannerDetail$.next(resp);
        }).catch((err) => {
            if (!err.Result) {
                this._router.navigate(['/maintenance'], { queryParams: { view: 'unAuthUser' } });
            }
        });

        this._router.events.subscribe(() => {
            this._uiHelper.toggleUnauthorized$.next(false);
        });
    }

    ngOnInit() {
        this._modalHelper.toggleLoadingModal$.subscribe(value => {
            if (value) {
                document.body.classList.add('loading-state');
            }
            else {
                document.body.classList.remove('loading-state');
            }
        });

        this._modalHelper.toggleSessionExpiredModal$.subscribe(value => {
            if (value) {
                this.modalSessionExpired.show();
            }
            else {
                this.modalSessionExpired.hide();
            }
        });

    }

    onClickRedirectToLogin() {
        location.href = './login';
    }
}
