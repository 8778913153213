export enum LoginStatus {
    TwoFaPending = 1,
    LoggedIn,
    LoggedOut
}

export enum UserType {
    'Agent' = 'Agent',
    'SuperAgent' = 'SuperAgent',
    'SuperUser' = 'SuperUser'
}

export enum SearchOrderType {
    'ASC' = 'ASC',
    'DESC' = 'DESC'
}

export const UserTypeToRedirectionPortal = {
    Agent: 'intermediary',
    SuperAgent: 'intermediary-admin',
    SuperUser: 'admin'
};

export enum CustomerMartialStatus {
    'Undefined' = 'Onbekend',
    'Single' = 'Alleenstaand',
    'Married' = 'Getrouwd',
    'MarriedWithPrenup' = 'Getrouwd met huwelijkse voorwaarden',
    'RegisteredPartner' = 'Geregistreerd partnerschap',
    'RegisteredPartnerWithConditions' = 'Geregistreerd partnerschap met voorwaarden',
    'Cohabiting' = 'Samenwonend',
    'CohabitingWithContract' = 'Samenlevingscontract',
    'Divorced' = 'Gescheiden'
}

export enum AssetType {
    'ConstructionDeposit' = 'ConstructionDeposit'
}

export enum Taxation {
    'Box1' = 'Box1',
    'Box3' = 'Box3',
    'Box9' = 'Box9',
    'Box1Before2013' = 'Box1Before2013',
    'Box1After2013' = 'Box1After2013'
}


export const DocumentType = {
    Gebruikershandleiding: 'Gebruikershandleiding',
    AcceptatieVoorwaarden: 'Acceptatie voorwaarden',
    Informatiekaart: 'Informatiekaart',
    Toolshandleiding: 'Tools & handleiding',
    Formulieren: 'Formulieren',
    Handleiding2FAAuthentication: 'Handleiding 2FA Authentication',
    Ondernemers: 'Ondernemers'
};

export enum ProfileUpdateType {
    Phone = 'Phone',
    Email = 'Email',
    FirstName = 'FirstName',
    LastName = 'LastName',
    FullName = 'Name'
}

export enum RedirectionPortalUrlEnum {
    Agent = 'intermediary',
    SuperAgent = 'intermediary-admin',
    SuperUser = 'admin'
}

export enum LoanPartTerms {
    BASIS = 'Basisvoorwaarden',
    PLUS = 'Plusvoorwaarden'
}

export enum LoanPartServicer {
    Rabobank = 'RABOBANK'
}

export enum CookieKeys {
    AgentOnboardingTour = 'agent_onboarding_tour',
    WelcomeBoxState = 'welcome_box_state',
    ClientBannerState = 'client_banner_state',
    ConsentLevel = 'consent_level'
}
export enum OverviewSearchProperties {
    KvkNumber = 'kvkNumber',
    ExternalReferenceId = 'externalReferenceId',
    Name = 'name',
    City = 'city'
}

export enum MaintenanceEnums {
    MajorDisruption = 'majorDisruption',
    UnAuthUser = 'unAuthUser'
}

export const TokenExpired = {
    TokenIsExpired: 'TOKEN IS EXPIRED',
};

export enum PasswordErrors {
    NewPasswordDifferentOldOne = 'NEW PASSWORD MUST BE DIFFERENT FROM OLD PASSWORD',
    WrongUserNameorPassword = 'WRONG USERNAME OR PASSWORD',
}

export enum UserListSearchProperties {
    firstName = 'profile.firstName',
    lastName = 'profile.lastName',
    email = 'profile.email'
}
export const TwoFaError = {
    TwoFaHasNotConfigured: 'TWO FACTOR AUTHENTICATION HAS NOT BEEN CONFIGURED FOR USER',
};

export enum LoanType {
    InterestOnlyMortgage = 'InterestOnlyMortgage',
}

export const Description = {
    LoanPartDescription: 'KeuzePlus Hypotheek',
}

export enum InterestOnlyMortgageType {
    KPH = 'KPH'
}

export const UserTypeToDomainUrl = {
    Agent: 'intermediair',
    SuperAgent: 'intermediair-admin',
    SuperUser: 'admin'
};

export enum StorageKeys {
    OrganizationId = 'organizationId',
    OrganizationName = 'organizationName',
    UserData = 'userData',
    Email = "email",
    OrganizationData = 'organizationData',
    IsTwoFAReset = 'isTwoFAReset'
}


export enum DocumentMessage {
    UploadSuccess = 'Uw document is succesvol geüpload',
    UploadError = 'Uw document is niet succesvol geüpload',
    DeleteSuccess = 'Uw document is succesvol verwijderd',
    DeleteError = 'Uw document is niet succesvol verwijderd'
}

export enum SyncTaskName {
    SynchSuperAgents = 'SynchSuperAgents'
}


export enum ResetType {
    SMSPassword = 'SMSPasswordReset',
    RegularPassword = 'RegularPasswordReset',
    TwoFA = 'TwoFAReset'
}

export enum RegexType {
    FirstLetterUpperCase = 'FirstLetterUpperCase',
    SpecialWord = 'SpecialWord',
    StartWithLetterS = 'StartWithLetterS',
    StartWithLetterIJ = 'StartWithLetterIJ'
}


export const FeedbackRadioButton = [
    { Type: 'Slecht', Value: 1 },
    { Type: 'Matig', Value: 2 },
    { Type: 'Neutraal', Value: 3 },
    { Type: 'Goed', Value: 4 },
    { Type: 'Fantastisch', Value: 5 },
]

export const CountryCode = '31';

export enum PortalUrl {
    Agent = '/intermediary/',
    SuperAgent = '/intermediary-admin/',
    SuperUser = '/admin/'
}

export enum DocumentMIMEType {
    ApplicationPDF = 'application/pdf'
}

export enum EmailType {
    ContactForm = 'ContactForm',
    GeneralFeedback = 'GeneralFeedback',
    SuperAgent = 'SuperAgent',
    Agent = 'Agent'
}

export const Provider = 'Microsoft';

export enum UserRole {
    SuperUserRole = 'SuperUserRole',
    AdministrativeSupportRole = 'RIO.AdministratieveOndersteuning'
}

export const HistoricalInterestDocument = {
    HistoricalInterestDocument: 'Historische rente'
}

export enum PageName {
    Dashboard = 'Dashboard',
    Welcome = 'Welcome',
    CustomersOverview = 'CustomersOverview',
    Customers = 'Customers',
    MortgageRequest = 'Mortgage-request',
}
